export default function Chevron(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 -256 1792 1792"
			id="svg3001"
			version="1.1"
			width="100%"
			height="100%"
			{...props}
		>
			<g transform="matrix(1,0,0,-1,68.338983,1133.5593)" id="g3003">
				<path
					d="m 1611,832 q 0,-53 -37,-90 L 923,91 Q 885,53 832,53 778,53 742,91 L 91,742 q -38,36 -38,90 0,53 38,91 l 74,75 q 39,37 91,37 53,0 90,-37 l 486,-486 486,486 q 37,37 90,37 52,0 91,-37 l 75,-75 q 37,-39 37,-91 z"
					id="path3005"
				/>
			</g>
		</svg>
	);
}
