function Link(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={512}
			height={512}
			viewBox="0 0 512 512"
			{...props}
		>
			<path
				fill="#010101"
				d="M459.654 233.373l-90.531 90.5c-49.969 50-131.031 50-181 0-7.875-7.844-14.031-16.688-19.438-25.813l42.063-42.063c2-2.016 4.469-3.172 6.828-4.531 2.906 9.938 7.984 19.344 15.797 27.156 24.953 24.969 65.563 24.938 90.5 0l90.5-90.5c24.969-24.969 24.969-65.563 0-90.516-24.938-24.953-65.531-24.953-90.5 0l-32.188 32.219c-26.109-10.172-54.25-12.906-81.641-8.891l68.578-68.578c50-49.984 131.031-49.984 181.031 0 49.97 49.986 49.97 131.033.001 181.017zM220.326 382.186l-32.203 32.219c-24.953 24.938-65.563 24.938-90.516 0-24.953-24.969-24.953-65.563 0-90.531l90.516-90.5c24.969-24.969 65.547-24.969 90.5 0 7.797 7.797 12.875 17.203 15.813 27.125 2.375-1.375 4.813-2.5 6.813-4.5l42.063-42.047c-5.375-9.156-11.563-17.969-19.438-25.828-49.969-49.984-131.031-49.984-181.016 0l-90.5 90.5c-49.984 50-49.984 131.031 0 181.031 49.984 49.969 131.031 49.969 181.016 0l68.594-68.594c-27.407 4.031-55.548 1.281-81.642-8.875z"
			/>
		</svg>
	);
}

export default Link;
